import * as React from "react"
import { graphql } from "gatsby"

import Layout from "../../components/layout/Layout"
import Container from "../../components/layout/Container"
import Type from "../../components/typography/Type"
import SearchNav from "../../components/search/SearchNav"
import { isLoggedIn } from "../../services/auth"
import LoginPrompt from "../../components/app/loginPrompt"


const Docs = ({ data, pageContext }) => (

<Layout>
<Container>

  {isLoggedIn() ? (
  <>
 <div className="flex flex-col space-y-12">
   <div className="bg-yellow py-2 px-6 rounded-full w-fit flex items-center">
      <Type type="p6" nopadding="yes">Design-led strategic story-telling</Type>
   </div>
   <div className="w-2/3">
    <Type type="h2">Get started with Framework</Type>
    <Type type="p4" nopadding="yes">Framework is a design-led strategic story-telling tool for your product & service portfolio. Framework helps you shape a compelling, 360º view of what you aim to create and how you'll achieve it.</Type>
   </div>
 </div>
 <div className="flex flex-col space-y-32 mt-32">
 <div className=" flex flex-row border-b-2 border-slate/50">
    {data.allAirtable.edges.map((edge) => (
     <div><a href={`#${edge.node.data.Slug}`}><button className="border-b-2 pb-4 px-12 text-grey hover:text-black hover:border-black border-slate/50 relative top-[2px]"><Type type="h4" nopadding="yes">{edge.node.data.Name}</Type></button></a></div>
    ))}
  </div>
   {data.allAirtable.edges.map((edge) => (
     <div id={`${edge.node.data.Slug}`}>
        <div className="w-1/2">
          <Type type="h3">{edge.node.data.Name}</Type>
          <Type type="p5">{edge.node.data.Introduction}</Type>
        </div>
      
      
      
      {edge.node.data.Layout === 'Grid' ?
      <div className="grid grid-cols-2 gap-24 mt-24">
        {edge.node.data.FrameworkDocsContent.map((ContentArray) => (
          <div className="">
            <Type type="h5">{ContentArray.data.Title}</Type>
            <Type type="p5">{ContentArray.data.Content}</Type>
          </div>
        ))}
      </div>
    :
      <div className="">
        {edge.node.data.FrameworkDocsContent.map((ContentArray) => (
          <div className="flex flex-row space-x-24 mt-24">
          <div className="w-1/2">
            <Type type="h5">{ContentArray.data.Title}</Type>
            <Type type="p5">{ContentArray.data.Content}</Type>
          </div>
          <div className="w-1/2">
          {ContentArray.data.ImageURL
            ?
            <img 
              src={ContentArray.data.ImageURL}
              alt={ContentArray.data.Title}
              className="w-full rounded-lg drop-shadow-lg"
              />
              :
              undefined
            }
          </div>
          </div>
        ))}
      </div>
    
      }
      
      
     </div>
    ))}
 </div>
 
  </>
) : (
  <>
    <LoginPrompt/>
  </>
)}




</Container>
</Layout>


)

export const Head = () => <title>Docs</title>

export default Docs

// query airtable for the Title and Path of each record,
// filtering for only records in the Sections table.
// sort: {fields: data___Order, order: ASC}
    
export const query = graphql` 
query ($skip: Int!, $limit: Int!) {
  allAirtable (
    filter: {queryName: {eq: "FrameworkDocsGroups"}}
    sort: {fields: data___Order, order: ASC}
    skip: $skip
    limit: $limit
  ) {
    edges {
      node {
        data {
          Name
          Slug
          Path
          Introduction
          Layout
          FrameworkDocsContent { data {
            Title
            Content
            ImageURL
            }
          }
        }
      }
    }
  }
}
`
